
import {
  infinityScrollTable,
  helperMixin,
  filterHelper,
  positionFilter
} from "@/mixins";
import { JobFilter, JobPaginate } from "@/models/job";
import { defineComponent } from "vue";
import jobHttp from "@/http/job";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import FilterModalContent from "@/components/FilterModalContent.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseLink from "@/components/Base/BaseLink.vue";

export default defineComponent({
  components: {
    ActionTableColumn,
    ConfirmModal,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    FilterModalContent,
    BaseFormGroup,
    BaseButtonDefault,
    BaseLink
  },
  mixins: [
    filterHelper<JobFilter>(),
    positionFilter<JobFilter>(),
    helperMixin,
    infinityScrollTable<JobPaginate, JobFilter>(jobHttp(), {
      position_in: []
    })
  ],
  data() {
    return {
      incomingApplicantEvent: null as null | Function,
      submittedFilter: {} as JobFilter
    };
  },
   mounted() {
    this.incomingApplicantEvent = this.pubSub.on(
      this.EventNames.IncomingApplication,
      () => this.reload()
    )
  },
  beforeUnmount() {
    this.incomingApplicantEvent ? this.incomingApplicantEvent() : "";
  }
});
