import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-2.5 py-1 text-red-500 hover:text-red-700 text-sm font-semibold border border-red-300 rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_action_table_column = _resolveComponent("action-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_filter_modal_content = _resolveComponent("filter-modal-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "9",
      onLoadMore: _ctx.loadMore,
      loading: _ctx.loading,
      hasMore: _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, {
              "text-align": "center",
              type: "label",
              class: "pr-0 w-0"
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "px-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.job_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.purpose_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.estimate_join")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              "text-align": "center",
              type: "label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.post_date")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              "text-align": "center",
              type: "label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.expired_date")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              "text-align": "center",
              type: "label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.sailor_applied_count")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_action_table_column, { type: "label" })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, {
                "text-align": "center",
                class: "pr-0 w-0 whitespace-nowrap"
              }, {
                default: _withCtx(() => [
                  (row.new_applicant)
                    ? (_openBlock(), _createBlock(_component_base_link, {
                        key: 0,
                        to: {
            name: _ctx.RouteNames.JobApplicant,
            params: {
              id: row.id
            }
          }
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", _hoisted_1, _toDisplayString(_ctx.trans("layout.job.new_applicant", {
                count: row.new_applicant
              })), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.position.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "whitespace-nowrap px-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.job_id), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.purpose.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.join_asap
          ? _ctx.trans("layout.asap")
          : row.estimate_join_date
          ? _ctx.formatDate(row.estimate_join_date)
          : ""), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { "text-align": "center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(row.created_at)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { "text-align": "center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(row.last_submission_date)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { "text-align": "center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.applied_count), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_action_table_column, {
                onDelete: ($event: any) => (_ctx.showConfirmDelete(row)),
                "edit-route": { name: _ctx.RouteNames.JobEdit, params: { id: row.id } }
              }, null, 8, ["onDelete", "edit-route"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_confirm_modal, {
      type: "delete",
      title: _ctx.trans('layout.modal_title.confirm_delete_job'),
      modelValue: _ctx.confirmDeleteModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmDeleteModal) = $event)),
      onYes: _ctx.deleteSelectedRow
    }, {
      default: _withCtx(() => [
        (_ctx.selectedRow)
          ? (_openBlock(), _createBlock(_component_base_table, {
              key: 0,
              type: "detail"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_table_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_table_column, {
                      "text-align": "left",
                      type: "label",
                      width: "100"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_base_table_column, { "text-align": "left" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedRow.position.name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_base_table_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_table_column, {
                      "text-align": "left",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.trans("layout.job.purpose_id")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_base_table_column, { "text-align": "left" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedRow.purpose.name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_base_table_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_table_column, {
                      "text-align": "left",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.trans("layout.job.estimate_join")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_base_table_column, { "text-align": "left" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedRow.join_asap
            ? _ctx.trans("layout.asap")
            : _ctx.formatDate(_ctx.selectedRow.estimate_join_date)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "modelValue", "onYes"]),
    _createVNode(_component_filter_modal_content, {
      title: _ctx.trans('layout.filter_job'),
      filter: _ctx.filter,
      "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event)),
      submittedFilter: _ctx.submittedFilter,
      "onUpdate:submittedFilter": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.submittedFilter) = $event)),
      onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reload())),
      onReset: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetAll(true))),
      onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.resetAll()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_form_group, {
          ref: "formGroupPosition",
          label: _ctx.trans('layout.job.position_id'),
          items: _ctx.selectionDataToBaseItem(_ctx.selectionStore.state.job_position),
          inputType: "multi-select",
          modelValue: _ctx.filter.position_in,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.position_in) = $event))
        }, null, 8, ["label", "items", "modelValue"])
      ]),
      _: 1
    }, 8, ["title", "filter", "submittedFilter"])
  ], 64))
}